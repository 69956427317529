'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useFormContext } from 'react-hook-form';
import { SvgSpriteIcon } from '../../svgSpriteIcon/SvgSpriteIcon';
import { inputClassNames } from './input.styles';
export const Input = ({ name, value, onChange, onBlur, type = 'text', design = 'primary', styles, beforeInput, afterInput, afterInputOuter, showValidationIcons = true, valueAsNumber = false, enforcePostCodeFormat = false, inputmode, hasError = false, ...props }) => {
    const { register, setValue } = useFormContext();
    const finalStyles = inputClassNames(styles, design);
    function postCodeOnChange(event) {
        if (event.target.value) {
            const valueWithoutDisallowedCharacters = event.target.value.replace(/[^0-9\s-]/g, '');
            event.target.value = valueWithoutDisallowedCharacters;
            setValue(name || '', valueWithoutDisallowedCharacters);
        }
    }
    function phoneOnChange(event) {
        if (event.target.value) {
            const valueWithoutDisallowedCharacters = event.target.value.replace(/[^0-9\s+]/g, '');
            event.target.value = valueWithoutDisallowedCharacters;
            setValue(name || '', valueWithoutDisallowedCharacters);
        }
    }
    const registerProps = register(name || '', {
        valueAsNumber: valueAsNumber,
        value: value,
        onChange: type === 'tel'
            ? phoneOnChange
            : enforcePostCodeFormat
                ? postCodeOnChange
                : onChange,
        onBlur: onBlur,
    });
    return (_jsxs("div", { className: twMergeJuan(finalStyles.controlContainer, finalStyles.controlContainer__custom, !!afterInputOuter && finalStyles.controlContainer__flex), children: [_jsxs("div", { className: twMergeJuan(finalStyles.container, finalStyles.container__custom, props.disabled && finalStyles.container__disabled, hasError && finalStyles.container__error), children: [beforeInput, _jsx("input", { ...props, type: enforcePostCodeFormat ? 'text' : type, inputMode: inputmode, value: value == null ? undefined : value, className: twMergeJuan(finalStyles.input, finalStyles.input__custom), ...registerProps }), showValidationIcons && hasError && (_jsx(SvgSpriteIcon, { className: finalStyles.errorIco, icon: "warning", ariaHidden: true })), afterInput] }), afterInputOuter] }));
};
