import { gql } from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client/react/hooks';
const defaultOptions = {};
export const RouteDocument = gql `
    query route($webId: String!, $id: String!, $params: JSONObject) {
  route(webId: $webId, id: $id, params: $params) {
    id
    locale
    prefix
    url
    pathname
    name
    type
    source
    params
    webId
  }
}
    `;
/**
 * __useRouteQuery__
 *
 * To run a query within a React component, call `useRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRouteQuery({
 *   variables: {
 *      webId: // value for 'webId'
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRouteQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(RouteDocument, options);
}
export function useRouteLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(RouteDocument, options);
}
export function useRouteSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(RouteDocument, options);
}
import { getGqlData } from '@edeeone/juan-core/graphql/getGqlData';
export const getData_RouteQuery = async function (options) {
    return getGqlData({
        ...options,
        query: RouteDocument,
    });
};
