import { formatNumber } from './formatNumber';
export const formatCurrency = function (num, currency, options) {
    // Pokud formátujeme měnu, tak logiku desetinných čísel necháváme čistě na Intl (který si to určuje sám dle locale)
    const extendedOptions = {
        ...options,
        maximumFractionDigits: undefined,
        minimumFractionDigits: undefined,
        trailingZeroDisplay: 'stripIfInteger',
    };
    // @ts-ignore
    return formatNumber(num, { style: 'currency', currency, ...extendedOptions });
};
