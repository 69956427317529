export const getHref = function (uri, query = {}, anchor) {
    if (!uri) {
        return '';
    }
    const uriBagQuery = uri?.split('?');
    const uriBagAnchor = uriBagQuery[0].split('#');
    const pathname = uriBagAnchor[0]?.replace(/([^:]\/)\/+/g, '$1');
    const computedAnchor = anchor || uriBagAnchor[1];
    const uriQueryString = uriBagQuery[1]
        ? uriBagQuery[1].split('#')[0]
        : undefined;
    let uriQuery = {};
    if (uriQueryString) {
        uriQuery = Object.fromEntries(new URLSearchParams(uriQueryString));
    }
    let computedQuery = {
        ...uriQuery,
        ...query,
    };
    Object.keys(computedQuery).forEach((key) => {
        return ((computedQuery[key] == null ||
            computedQuery[key] === '' ||
            (Array.isArray(computedQuery[key]) &&
                computedQuery[key].length === 0)) &&
            delete computedQuery[key]);
    });
    const keys = Object.keys(computedQuery);
    const hash = computedAnchor && computedAnchor !== '' ? `#${computedAnchor}` : '';
    if (keys.length === 0) {
        return `${pathname || ''}${hash}`;
    }
    const searchParams = new URLSearchParams(computedQuery);
    searchParams.sort();
    return `${pathname}?${searchParams.toString()}${hash}`;
};
