'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { SvgSpriteIcon } from '../../svgSpriteIcon/SvgSpriteIcon';
import { useField } from '../field';
import { checkboxClassNames } from './checkbox.styles';
export const Checkbox = function (props) {
    const { name, checkedValue, styles, label, children, count, disabled, design = 'primary', checked, size, style, } = props;
    const { id, value, onChange, onBlur, error } = useField({
        undefinedValue: '',
        ...props,
    });
    const finalChecked = checked != null && checkedValue == null
        ? checked
        : isEqual(checkedValue, value);
    const finalStyles = checkboxClassNames(styles, design, size, {
        disabled,
        checked: finalChecked,
    });
    const handleChange = useCallback((e) => {
        if (onChange) {
            const checked = e.target.checked;
            const newValue = checkedValue == null ? checked : checked ? checkedValue : undefined;
            // console.log(
            //   'Checkbox',
            //   'handleChange',
            //   newValue,
            //   checked,
            //   checkedValue
            // );
            onChange(newValue, checked, checkedValue);
        }
    }, [onChange, checkedValue]);
    return (_jsxs("div", { style: style, className: twMergeJuan(finalStyles.container, finalStyles.container__custom, finalChecked && finalStyles.container__checked, disabled && finalStyles.container__disabled), children: [_jsxs("div", { className: twMergeJuan(finalStyles.content, finalStyles.content__custom), children: [_jsx("input", { id: id, disabled: disabled, type: 'checkbox', className: twMergeJuan(finalStyles.input, disabled && finalStyles.input__disabled), name: name, onChange: handleChange, onBlur: onBlur, checked: finalChecked }), _jsx(SvgSpriteIcon, { icon: "formCheck", ariaHidden: true, className: finalStyles.icon })] }), (label || children) && (_jsxs("label", { className: twMergeJuan(finalStyles.label, finalStyles.label__custom, disabled && finalStyles.label__disabled), htmlFor: id, children: [label && (_jsx("span", { className: twMergeJuan(finalStyles.label_text, finalChecked && finalStyles.label_text__checked), children: label })), count != null && (_jsxs("span", { className: finalStyles.badge, children: [" (", count, ")"] })), !label && children] })), error && _jsx("div", { className: finalStyles.error, children: error })] }));
};
