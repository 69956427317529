export const normalizeNumber = function (num) {
    if (num == null) {
        return undefined;
    }
    let finalNum = num;
    if (typeof num === 'string') {
        finalNum = parseFloat(num);
    }
    if (isNaN(finalNum)) {
        return undefined;
    }
    return finalNum;
};
