import { useLayoutEffect, useEffect, useRef } from 'react';
export function yieldToMain(options) {
    if ('scheduler' in window) {
        const scheduler = window.scheduler;
        if ('yield' in scheduler)
            return scheduler.yield(options);
        if ('postTask' in scheduler)
            return scheduler.postTask(() => { }, options);
    }
    if (options?.priority === 'user-blocking') {
        return Promise.resolve();
    }
    return new Promise((resolve) => {
        return setTimeout(resolve);
    });
}
export function interactionResponse(options) {
    return new Promise((resolve) => {
        requestAnimationFrame(() => {
            setTimeout(resolve);
        });
    });
}
export function abortableInteractionResponse(signal) {
    let rafCleanup;
    return new Promise((resolve, reject) => {
        if (signal.aborted)
            return reject(signal);
        signal.addEventListener('abort', () => {
            reject(signal);
            if (rafCleanup !== undefined)
                cancelAnimationFrame(rafCleanup);
        });
        rafCleanup = requestAnimationFrame(() => {
            setTimeout(resolve);
        });
    }).catch((error) => {
        if (error instanceof AbortSignal && error.aborted)
            return;
        throw error;
    });
}
export function useAbortableAfterPaintEffect(fn, deps) {
    const controllerRef = useRef(null);
    useEffect(() => {
        controllerRef.current?.abort();
        const nextController = new AbortController();
        controllerRef.current = nextController;
        const { signal } = controllerRef.current;
        const runAfterPaint = async () => {
            await abortableInteractionResponse(signal);
            fn();
        };
        runAfterPaint();
        return () => {
            nextController.abort();
            controllerRef.current?.abort();
        };
    }, deps);
}
export function useAfterPaintEffect(effectFn, deps, opts, useEffectFn = useLayoutEffect) {
    useEffectFn(() => {
        const runAfterPaint = async (fn) => {
            await interactionResponse(opts);
            return fn();
        };
        const runPromise = runAfterPaint(effectFn);
        return () => {
            (async () => {
                const cleanup = await runPromise;
                if (typeof cleanup === 'function') {
                    runAfterPaint(cleanup);
                }
            })();
        };
    }, deps);
}
export function useYieldEffect(fn, deps, opts) {
    useEffect(() => {
        let cleanup;
        const controller = new AbortController();
        const run = async () => {
            await yieldToMain({ signal: controller.signal, ...opts });
            cleanup = fn();
        };
        run();
        return () => {
            controller.abort();
            if (typeof cleanup === 'function') {
                cleanup();
            }
        };
    }, deps);
}
