import { useCallback, useEffect } from 'react';
/**
 * Hook invokes a callback function whenever the URL pathname changes. It offers optional functionality
 * to include search parameters in the comparison and to handle repeated clicks on the same URL.
 *
 * @param {() => void} callback - The function called on each pathname change.
 * @param {boolean} [includeSearchParams=false] - Optional. When set to true,
 * search parameters are included in URL comparison. Defaults to false.
 * @param {boolean} [enableSameLinkClick=false] - Optional. When set to true,
 * the callback is triggered even when clicking on a link to the current URL. Defaults to false.
 */
export const useChangePathname = ({ callback, includeSearchParams = false, enableSameLinkClick = false, }) => {
    const handleClick = useCallback((event) => {
        const target = event.target;
        const closestNextLink = target.closest('a[href]');
        if (closestNextLink &&
            closestNextLink.href === window.location.href) {
            callback();
        }
    }, [callback]);
    useEffect(() => {
        if (enableSameLinkClick) {
            document.addEventListener('click', handleClick);
        }
        if (includeSearchParams) {
            document.addEventListener('locationUrlChange', callback);
        }
        else {
            document.addEventListener('locationPathnameChange', callback);
        }
        //NOTE: Cleanup listener
        return () => {
            if (enableSameLinkClick) {
                document.removeEventListener('click', handleClick);
            }
            if (includeSearchParams) {
                document.removeEventListener('locationUrlChange', callback);
            }
            else {
                document.removeEventListener('locationPathnameChange', callback);
            }
        };
        // NOTE: We want call only on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
