import { useEffect, useId, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
const propsForPick = [
    'name',
    'onChange',
    'onBlur',
    'value',
    'rules',
    'undefinedValue',
    'id',
];
/**
 * Hook for compatibility with/without react-hook-form provider and custom state management
 */
export function useField({ name, onChange, onBlur, value, rules, undefinedValue, id, }) {
    const generatedId = useId();
    const ctx = useFormContext();
    const { formState, setValue, register = (_name) => {
        return {
            onChange,
            onBlur,
            name,
        };
    }, watch = (_name) => {
        return value;
    }, } = (ctx || {});
    const valueRef = useRef(value);
    const watchedValue = watch(name);
    useEffect(() => {
        valueRef.current = watchedValue || undefinedValue;
    }, [watchedValue, undefinedValue]);
    // @ts-ignore
    const registered = register(name, { rules });
    const error = name ? formState?.errors?.[name] : undefined;
    return {
        error,
        id: id || generatedId,
        value: value || watchedValue || undefinedValue,
        onBlur: (e) => {
            const event = e ||
                {
                    target: {
                        name,
                        value: valueRef.current,
                    },
                };
            if (onBlur == null) {
                registered?.onBlur?.(event);
            }
            else {
                onBlur(event);
            }
        },
        onChange: (e, ...args) => {
            // @ts-ignore
            const value = e?.target?.value || e;
            const finalValue = value || undefinedValue;
            valueRef.current = finalValue;
            if (onChange == null && setValue != null && name != null) {
                // @ts-ignore
                setValue(name, finalValue);
            }
            if (onChange != null) {
                // @ts-ignore
                onChange?.(finalValue, name, ...args);
            }
        },
    };
}
