import { getMemoizedFinalClassnames } from '../../../hooks/useFinalClassnames';
export const checkboxDefaultClassNames = /* tw */ {
    container: 'form-group form-group--checkbox flex items-start group/checkbox',
    container__checked: 'is_checked',
    container__custom: '',
    container__disabled: '',
    content: 'form-group__content relative h-8 w-8 mr-4 flex shrink-0 items-center',
    content__custom: '',
    input: 'form-group__input absolute outline-none appearance-none h-8 w-8 border-checkbox checked:bg-black radius-checkbox bg-white cursor-pointer peer transition-common',
    input__disabled: 'opacity-50 cursor-default',
    label: 'form-group__label inline-block text-sm font-normal cursor-pointer',
    label__custom: '',
    label__disabled: 'opacity-50 cursor-default',
    label_text: 'form-group__label-text',
    label_text__checked: '',
    badge: 'form-group__badge',
    icon: 'form-group__ico absolute hidden pointer-events-none peer-checked:block fill-white w-4 h-4 mx-[.5rem]',
    error: 'form-group__error text-red text-sm',
    // samostatný checkbox
    primary: {
        label: 'text-xs text-gray-70 text-left',
    },
    // checkbox ve skupině ve vertikálním filtru
    secondary: {
        content: 'mt-1',
        label_text: 'mr-2',
        label_text__checked: 'text-black font-medium',
        label: 'text-gray-70',
        badge: 'text-gray-30',
    },
    // checkbox ve skupině v horizontálním filtru
    tertiary: {
        groupContainer: 'max-h-[160px] overflow-y-auto custom-scrollbar',
        label_text: 'mr-2',
        label_text__checked: 'text-black font-medium',
        label: 'text-gray-70',
        badge: 'text-gray-30',
    },
    // samostatný checkbox v horizontálním filtru
    quaternary: {
        container: 'filters__group filters__group--standalone !gap-0 !items-center border border-gray-30 hover:border-black focus-within:border-black text-gray-70 pl-8 pr-10 py-4 radius-common transition-common',
        container__disabled: 'opacity-50 hover:border-gray-30',
        label: 'uppercase text-xs text-gray-70 font-semibold tracking-wide leading-10 whitespace-nowrap',
        badge: 'text-gray-30 !font-normal',
    },
    // samostatný checkbox ve vertikálním filtru
    quinary: {
        container: 'border-b border-b-gray-20 px-6 py-8',
        content: 'mt-1',
        label_text: 'mr-2',
        label_text__checked: 'text-black font-medium',
        label: 'text-gray-70',
        badge: 'text-gray-30',
    },
    // větší verze primary checkboxu (Kontaktní údaje v košíku)
    senary: {
        label: 'text-gray-70',
    },
    // toggle checkbox (nastavení cookie lišty)
    septenary: {
        content: 'h-[2.2rem] w-[4.2rem] rounded-full transition-common',
        input: 'relative bg-gray-30 border-none border-0 h-full w-full !rounded-full checked:bg-success before:absolute before:top-[.3rem] before:left-[.3rem] before:w-[1.6rem] before:h-[1.6rem] before:bg-white before:rounded-full before:transition-common checked:before:left-[2.3rem]',
        label_text: 'text-gray-60',
        label_text__checked: 'text-success',
        label: 'hidden lg:inline-block !text-xs text-gray-70 min-w-[6rem]',
        badge: 'text-gray-30',
        icon: 'peer-checked:hidden',
    },
};
export function checkboxClassNames(...args) {
    return getMemoizedFinalClassnames('checkboxClassNames', checkboxDefaultClassNames, ...args);
}
