import memoize from 'lodash/memoize';
import { normalizeNumber } from './normalizeNumber';
const getFormatter = function (options) {
    const { locales, ...restOptions } = options || {};
    return new Intl.NumberFormat(locales, {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        ...restOptions,
    });
};
export const formatNumber = function (num, options) {
    const finalNum = normalizeNumber(num);
    if (finalNum == null) {
        return undefined;
    }
    const _formatNumber = memoize(getFormatter);
    return _formatNumber(options).format(finalNum);
};
