'use client';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { useRouteQuery } from './route.codegen';
export const useLinkRoute = ({ webId, id, params, skip, }) => {
    const { webId: locationWebId } = useLocation();
    const { data } = useRouteQuery({
        variables: {
            webId: webId ?? locationWebId,
            id,
            params,
        },
        skip,
    });
    /*  console.log(
      'useLinkRoute client data',
      locale?.toString() || 'empty',
      id,
      params,
      data
    );*/
    return data?.route;
};
