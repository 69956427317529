import { gql } from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client/react/hooks';
const defaultOptions = {};
export const FieldsPriceFragmentDoc = gql `
    fragment fieldsPrice on PriceForSale {
  priceWithTax
  priceWithoutTax
  currency
  validity
}
    `;
export const ProductPricesFieldsNoLocaleFragmentDoc = gql `
    fragment productPricesFieldsNoLocale on Product {
  multiplePricesForSaleAvailable(currency: $currency)
  priceForSale(validNow: true, currency: $currency) {
    ...fieldsPrice
  }
  allPricesForSale(priceLists: $priceLists, currency: $currency) {
    accompanyingPrice(priceLists: $priceLists) {
      priceWithTax
      priceWithoutTax
      currency
      validity
    }
    ...fieldsPrice
  }
  attributes {
    saleEnded
  }
}
    ${FieldsPriceFragmentDoc}`;
export const CartFragmentFragmentDoc = gql `
    fragment cartFragment on CartResponse {
  id
  created
  updated
  data {
    currency
    heurekaOverenoOption
    rounding
    totalPriceToPay
    payment
    shipping
    customerType
    customerEmail
    customerPhone
    billingAddress {
      firstName
      lastName
      fullName
      street
      city
      postCode
      countryCode
      companyName
      regNumber
      taxRegNumber
      idUserAddress
    }
    deliveryAddress {
      firstName
      lastName
      fullName
      street
      city
      postCode
      countryCode
      pickupPointName
      companyName
      idUserAddress
    }
    note
    differentDeliveryAddress
    roundingStrategyCode
    priceListCodes
    totalPriceToPayBeforeRounding
    totalPriceOfItemsToPay
    totalPriceOfItemsToPayBeforeRounding
    totalPriceToPayRounding
    totalPriceWithoutPolicies {
      withTax
      withoutTax
      tax
    }
    totalPrice {
      withTax
      withoutTax
      tax
    }
    totalPriceOfItems {
      withTax
      withoutTax
      tax
    }
    totalPriceOfItemsWithoutPolicies {
      withTax
      withoutTax
      tax
    }
    totalPriceOfItemsToPayRounding
    totalPriceWithoutPoliciesGroupByTaxRate
    totalPriceGroupByTaxRate
    totalPriceOfItemsGroupByTaxRate
    productBundleDescriptor
    voucherCodes
    zboziGdprOption
    voucherFormCounter
    items {
      adjustment
      id
      key {
        bonusProductId
        bundleId
        index
        requiredCartItemId
      }
      type
      quantity
      productCode
      requiredCartItemId
      totalPriceWithoutPolicies {
        withoutTax
        withTax
        tax
      }
      totalPrice {
        withoutTax
        withTax
        tax
      }
      calculationItemDescription
      unitPrice {
        withoutTax
        withTax
        tax
      }
      unitPriceAfterPricePolicies {
        withoutTax
        withTax
        tax
      }
      stockItemId
      productId
      bundleItemIndex
      product {
        primaryKey
        categories {
          referencedEntity {
            attributes {
              code
            }
          }
        }
        attributes {
          codeShort
          name
          url
          relatedFiles {
            fileIds
            files {
              alt
              src
              url
              name
              description
            }
          }
          unit
        }
        ...productPricesFieldsNoLocale
      }
    }
    increment
    affiliateParameters
    pricePolicies
    paymentRequired
    messages {
      code
      messageArgs
      closed
      type
      id
    }
  }
  dataHashCode
  dataUpdated
  usedVoucherCodes
  finished
  affiliateParameters
}
    ${ProductPricesFieldsNoLocaleFragmentDoc}`;
export const PickupPointDocument = gql `
    query pickupPoint($args: PickupPointArgs) {
  pickupPoint(args: $args) {
    a {
      k
      n
      d
      s
      c
      p
      g
      t
      x
    }
  }
}
    `;
/**
 * __usePickupPointQuery__
 *
 * To run a query within a React component, call `usePickupPointQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupPointQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupPointQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function usePickupPointQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(PickupPointDocument, options);
}
export function usePickupPointLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(PickupPointDocument, options);
}
export function usePickupPointSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(PickupPointDocument, options);
}
export const CartGetCartDocument = gql `
    query cartGetCart($args: CartGetArgs, $currency: CatalogCurrency, $priceLists: [String!]! = ["reference"]) {
  cartGetCart(args: $args) {
    ...cartFragment
  }
}
    ${CartFragmentFragmentDoc}`;
/**
 * __useCartGetCartQuery__
 *
 * To run a query within a React component, call `useCartGetCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartGetCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartGetCartQuery({
 *   variables: {
 *      args: // value for 'args'
 *      currency: // value for 'currency'
 *      priceLists: // value for 'priceLists'
 *   },
 * });
 */
export function useCartGetCartQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CartGetCartDocument, options);
}
export function useCartGetCartLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CartGetCartDocument, options);
}
export function useCartGetCartSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CartGetCartDocument, options);
}
export const CartGetCartWithRefreshDocument = gql `
    query cartGetCartWithRefresh($args: CartUpdateCartArgs, $currency: CatalogCurrency, $priceLists: [String!]! = ["reference"]) {
  cartGetCartWithRefresh(args: $args) {
    cart {
      ...cartFragment
    }
    results {
      updated
      messages {
        productCode
        type
        code
        messageArgs
      }
      number
      customerEmail
      customerHash
    }
  }
}
    ${CartFragmentFragmentDoc}`;
/**
 * __useCartGetCartWithRefreshQuery__
 *
 * To run a query within a React component, call `useCartGetCartWithRefreshQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartGetCartWithRefreshQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartGetCartWithRefreshQuery({
 *   variables: {
 *      args: // value for 'args'
 *      currency: // value for 'currency'
 *      priceLists: // value for 'priceLists'
 *   },
 * });
 */
export function useCartGetCartWithRefreshQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CartGetCartWithRefreshDocument, options);
}
export function useCartGetCartWithRefreshLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CartGetCartWithRefreshDocument, options);
}
export function useCartGetCartWithRefreshSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CartGetCartWithRefreshDocument, options);
}
export const CartGetCartByHashDocument = gql `
    query cartGetCartByHash($args: CartGetByHashArgs, $currency: CatalogCurrency, $priceLists: [String!]! = ["reference"]) {
  cartGetCartByHash(args: $args) {
    ...cartFragment
  }
}
    ${CartFragmentFragmentDoc}`;
/**
 * __useCartGetCartByHashQuery__
 *
 * To run a query within a React component, call `useCartGetCartByHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartGetCartByHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartGetCartByHashQuery({
 *   variables: {
 *      args: // value for 'args'
 *      currency: // value for 'currency'
 *      priceLists: // value for 'priceLists'
 *   },
 * });
 */
export function useCartGetCartByHashQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CartGetCartByHashDocument, options);
}
export function useCartGetCartByHashLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CartGetCartByHashDocument, options);
}
export function useCartGetCartByHashSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CartGetCartByHashDocument, options);
}
export const CartShareEmailDocument = gql `
    mutation cartShareEmail($args: CartGetArgs, $input: CartShareEmailInput) {
  cartShareEmail(args: $args, input: $input)
}
    `;
/**
 * __useCartShareEmailMutation__
 *
 * To run a mutation, you first call `useCartShareEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartShareEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartShareEmailMutation, { data, loading, error }] = useCartShareEmailMutation({
 *   variables: {
 *      args: // value for 'args'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCartShareEmailMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(CartShareEmailDocument, options);
}
export const CartSingleUpdateCartDocument = gql `
    mutation cartSingleUpdateCart($currency: CatalogCurrency, $priceLists: [String!]! = ["reference"], $args: CartUpdateCartArgs, $input: CartUpdateCartInput) {
  cartSingleUpdateCart(args: $args, input: $input) {
    cart {
      ...cartFragment
    }
    results {
      updated
      messages {
        productCode
        type
        code
        messageArgs
      }
      number
      customerEmail
      customerHash
    }
  }
}
    ${CartFragmentFragmentDoc}`;
/**
 * __useCartSingleUpdateCartMutation__
 *
 * To run a mutation, you first call `useCartSingleUpdateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartSingleUpdateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartSingleUpdateCartMutation, { data, loading, error }] = useCartSingleUpdateCartMutation({
 *   variables: {
 *      currency: // value for 'currency'
 *      priceLists: // value for 'priceLists'
 *      args: // value for 'args'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCartSingleUpdateCartMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(CartSingleUpdateCartDocument, options);
}
export const CartMultiUpdateCartDocument = gql `
    mutation cartMultiUpdateCart($currency: CatalogCurrency, $priceLists: [String!]! = ["reference"], $args: CartUpdateCartArgs, $input: [CartUpdateCartInput]) {
  cartMultiUpdateCart(args: $args, input: $input) {
    cart {
      ...cartFragment
    }
    results {
      updated
      messages {
        productCode
        type
        code
        messageArgs
      }
      number
      customerEmail
      customerHash
    }
  }
}
    ${CartFragmentFragmentDoc}`;
/**
 * __useCartMultiUpdateCartMutation__
 *
 * To run a mutation, you first call `useCartMultiUpdateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartMultiUpdateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartMultiUpdateCartMutation, { data, loading, error }] = useCartMultiUpdateCartMutation({
 *   variables: {
 *      currency: // value for 'currency'
 *      priceLists: // value for 'priceLists'
 *      args: // value for 'args'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCartMultiUpdateCartMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(CartMultiUpdateCartDocument, options);
}
export const CartGetBonusDocument = gql `
    query cartGetBonus($args: CartGetArgs) {
  cartGetBonus(args: $args) {
    productId
    bonusProduct {
      bundleId
      productId
      item {
        productCatalogEntityId
        quantity
        priceStrategy
      }
      bonusProductPrice {
        withoutTax
        withTax
        tax
      }
      currency
    }
  }
}
    `;
/**
 * __useCartGetBonusQuery__
 *
 * To run a query within a React component, call `useCartGetBonusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartGetBonusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartGetBonusQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCartGetBonusQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CartGetBonusDocument, options);
}
export function useCartGetBonusLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CartGetBonusDocument, options);
}
export function useCartGetBonusSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CartGetBonusDocument, options);
}
export const CartGetShareDocument = gql `
    query cartGetShare($args: CartGetArgs) {
  cartGetShare(args: $args)
}
    `;
/**
 * __useCartGetShareQuery__
 *
 * To run a query within a React component, call `useCartGetShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartGetShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartGetShareQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCartGetShareQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CartGetShareDocument, options);
}
export function useCartGetShareLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CartGetShareDocument, options);
}
export function useCartGetShareSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CartGetShareDocument, options);
}
export const CartGetPaymentsDocument = gql `
    query cartGetPayments($args: CartGetArgs) {
  cartGetPayments(args: $args) {
    primaryKey
    name
    paymentType
    descriptionShort
    totalPrice {
      withTax
      withoutTax
      tax
    }
  }
}
    `;
/**
 * __useCartGetPaymentsQuery__
 *
 * To run a query within a React component, call `useCartGetPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartGetPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartGetPaymentsQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCartGetPaymentsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CartGetPaymentsDocument, options);
}
export function useCartGetPaymentsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CartGetPaymentsDocument, options);
}
export function useCartGetPaymentsSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CartGetPaymentsDocument, options);
}
export const CartGetShippingDocument = gql `
    query cartGetShipping($args: CartGetArgs) {
  cartGetShipping(args: $args) {
    primaryKey
    name
    pickUpBranchMethodSelect
    shippingType
    descriptionShort
    requiredCustomerDeliveryAddress
    requiredPersonalPickupLocation
    totalPrice {
      withTax
      withoutTax
      tax
    }
  }
}
    `;
/**
 * __useCartGetShippingQuery__
 *
 * To run a query within a React component, call `useCartGetShippingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartGetShippingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartGetShippingQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCartGetShippingQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CartGetShippingDocument, options);
}
export function useCartGetShippingLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CartGetShippingDocument, options);
}
export function useCartGetShippingSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CartGetShippingDocument, options);
}
export const CartGetShippingProductDocument = gql `
    query cartGetShippingProduct($args: CartGetArgs) {
  cartGetShippingProduct(args: $args) {
    primaryKey
    name
    pickUpBranchMethodSelect
    shippingType
    descriptionShort
    requiredCustomerDeliveryAddress
    requiredPersonalPickupLocation
    totalPrice {
      withTax
      withoutTax
      tax
    }
  }
}
    `;
/**
 * __useCartGetShippingProductQuery__
 *
 * To run a query within a React component, call `useCartGetShippingProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartGetShippingProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartGetShippingProductQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCartGetShippingProductQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CartGetShippingProductDocument, options);
}
export function useCartGetShippingProductLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CartGetShippingProductDocument, options);
}
export function useCartGetShippingProductSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CartGetShippingProductDocument, options);
}
export const CartGetShippingCountriesDocument = gql `
    query cartGetShippingCountries($args: CartGetArgs) {
  cartGetShippingCountries(args: $args)
}
    `;
/**
 * __useCartGetShippingCountriesQuery__
 *
 * To run a query within a React component, call `useCartGetShippingCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartGetShippingCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartGetShippingCountriesQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCartGetShippingCountriesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CartGetShippingCountriesDocument, options);
}
export function useCartGetShippingCountriesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CartGetShippingCountriesDocument, options);
}
export function useCartGetShippingCountriesSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CartGetShippingCountriesDocument, options);
}
export const CartGetBillingCountriesDocument = gql `
    query cartGetBillingCountries($args: CartGetArgs) {
  cartGetBillingCountries(args: $args)
}
    `;
/**
 * __useCartGetBillingCountriesQuery__
 *
 * To run a query within a React component, call `useCartGetBillingCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartGetBillingCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartGetBillingCountriesQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCartGetBillingCountriesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CartGetBillingCountriesDocument, options);
}
export function useCartGetBillingCountriesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CartGetBillingCountriesDocument, options);
}
export function useCartGetBillingCountriesSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CartGetBillingCountriesDocument, options);
}
export const CartGetShippingDiscountDocument = gql `
    query cartGetShippingDiscount($args: CartGetArgs) {
  cartGetShippingDiscount(args: $args) {
    discountShippingMinLimit
    discountShippingPrice
    progressBar
    remainingValueToDiscount
    totalOrderPricePolicy
  }
}
    `;
/**
 * __useCartGetShippingDiscountQuery__
 *
 * To run a query within a React component, call `useCartGetShippingDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartGetShippingDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartGetShippingDiscountQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCartGetShippingDiscountQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CartGetShippingDiscountDocument, options);
}
export function useCartGetShippingDiscountLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CartGetShippingDiscountDocument, options);
}
export function useCartGetShippingDiscountSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CartGetShippingDiscountDocument, options);
}
export const CartGetVoucherValidDocument = gql `
    query cartGetVoucherValid($args: CartGetArgs) {
  cartGetVoucherValid(args: $args) {
    isAnyValid
  }
}
    `;
/**
 * __useCartGetVoucherValidQuery__
 *
 * To run a query within a React component, call `useCartGetVoucherValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartGetVoucherValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartGetVoucherValidQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCartGetVoucherValidQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CartGetVoucherValidDocument, options);
}
export function useCartGetVoucherValidLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CartGetVoucherValidDocument, options);
}
export function useCartGetVoucherValidSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CartGetVoucherValidDocument, options);
}
export const CartGetShareDataDocument = gql `
    query cartGetShareData($args: CartGetShareDataArgs, $currency: CatalogCurrency, $priceLists: [String!]! = ["reference"]) {
  cartGetShareData(args: $args) {
    id
    data {
      id
      productId
      unitCount
      product {
        primaryKey
        attributes {
          name
          url
          relatedFiles {
            fileIds
            files {
              url
              name
              description
            }
          }
          unit
        }
        ...productPricesFieldsNoLocale
      }
    }
    pageNumbersNavigation
    pageNumber
    lastPageNumber
    totalNumberOfRecords
    pageSize
  }
}
    ${ProductPricesFieldsNoLocaleFragmentDoc}`;
/**
 * __useCartGetShareDataQuery__
 *
 * To run a query within a React component, call `useCartGetShareDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartGetShareDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartGetShareDataQuery({
 *   variables: {
 *      args: // value for 'args'
 *      currency: // value for 'currency'
 *      priceLists: // value for 'priceLists'
 *   },
 * });
 */
export function useCartGetShareDataQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CartGetShareDataDocument, options);
}
export function useCartGetShareDataLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CartGetShareDataDocument, options);
}
export function useCartGetShareDataSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CartGetShareDataDocument, options);
}
import { getGqlData } from '@edeeone/juan-core/graphql/getGqlData';
export const getData_PickupPointQuery = async function (options) {
    return getGqlData({
        ...options,
        query: PickupPointDocument,
    });
};
export const getData_CartGetCartQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CartGetCartDocument,
    });
};
export const getData_CartGetCartWithRefreshQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CartGetCartWithRefreshDocument,
    });
};
export const getData_CartGetCartByHashQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CartGetCartByHashDocument,
    });
};
export const getData_CartGetBonusQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CartGetBonusDocument,
    });
};
export const getData_CartGetShareQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CartGetShareDocument,
    });
};
export const getData_CartGetPaymentsQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CartGetPaymentsDocument,
    });
};
export const getData_CartGetShippingQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CartGetShippingDocument,
    });
};
export const getData_CartGetShippingProductQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CartGetShippingProductDocument,
    });
};
export const getData_CartGetShippingCountriesQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CartGetShippingCountriesDocument,
    });
};
export const getData_CartGetBillingCountriesQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CartGetBillingCountriesDocument,
    });
};
export const getData_CartGetShippingDiscountQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CartGetShippingDiscountDocument,
    });
};
export const getData_CartGetVoucherValidQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CartGetVoucherValidDocument,
    });
};
export const getData_CartGetShareDataQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CartGetShareDataDocument,
    });
};
