'use client';
import { useCallback, useEffect, useState } from 'react';
import { useAfterPaintEffect } from '@edeeone/juan-core/utils/cwv';
const DEFAULT_WIDTH = 1920;
const getWidth = () => {
    if (typeof window !== 'undefined') {
        return (window?.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth);
    }
    return DEFAULT_WIDTH;
};
const bp = (process.env.JUAN_TW_SCREENS || {});
const bpArray = Object.keys(bp).map((key) => {
    return {
        name: key,
        size: parseInt(bp[key]),
    };
});
const breakpointList = bpArray?.sort((a, b) => {
    return a?.size - b?.size;
});
function getBreakpoints(forcedWidth) {
    const breakpoints = breakpointList;
    if (typeof breakpoints !== 'object' || breakpoints === null) {
        throw new Error('Invalid configuration object!');
    }
    const width = forcedWidth || getWidth();
    // const result = [];
    const results = breakpoints?.filter((bp) => {
        return bp.size <= width;
    });
    return results;
}
export function useBreakpoints() {
    const [breakpoints, setBreakpoints] = useState(
    // DEFAULT_WIDTH because it needs to be the same width on server and on the first render
    getBreakpoints(DEFAULT_WIDTH)?.map((bp) => {
        return bp.name;
    }));
    const updateBreakpoints = useCallback(() => {
        setBreakpoints(getBreakpoints()?.map((bp) => {
            return bp?.name;
        }));
    }, []);
    useAfterPaintEffect(updateBreakpoints, [], { priority: 'background' });
    useEffect(() => {
        let timeoutId;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                updateBreakpoints();
            }, 150);
        };
        window.addEventListener('resize', resizeListener, {
            passive: true,
        });
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    });
    return {
        breakpoints,
        currentBreakpoint: breakpoints?.[(breakpoints?.length || 1) - 1],
    };
}
